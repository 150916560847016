import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import Blog from './Components/Blog';
import Resume from './Components/Resume';
import Navbar from './Components/Navbar';
import Projects from './Components/Projects';
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadFull} from "tsparticles";
import FutureAI from "./Components/FutureAI";
// import ola from "./assets/ola.jpeg";
import eye from "./assets/eye.png";
import img1 from "./assets/img1.png";
import img3 from "./assets/img3.png";
import code from "./assets/code.png";
import ahead3 from "./assets/ahead3.png";
import pawster2 from "./assets/pawster2.png";
import "./App.css";
import insta from './assets/insta.png'
import linkedin from './assets/linkedin.png'
import github from './assets/github.png'
import particlesOptions from "./particles.json";
import Button from '@mui/material/Button';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Contact from "./Components/Contact";
import Skills from "./Components/Skills";
import Tutorials from "./Components/Tutorials";
import Quantum from "./Components/Quantum";
import HTCSS from "./Components/HTCSS";
import React_Tutorial from "./Components/React_Tuorial";
import AI_dev from "./Components/AI_dev";
import Transformer from "./Components/Transformer";
import AI_Programmer from './Components/AI_Programmer';
import Command_line from './Components/Command_line';
import Learn from "./Components/Learn";
import Soccer from "./Components/Soccer";
import Tensor from "./Components/Tensor";
import Podcast from "./Components/Podcast";
// import {AuthContextProvider} from './Context/AuthContext';
import Register from "./Components/Register";
import SignUp from "./Components/SignUp";
import Signin from "./Components/Signin";
import FourOFour from "./Components/Four0Four";
import Footer from "./Components/Footer";
import Courses from "./Components/Courses";
import Developer from "./Components/Developer";
import Linear from "./Components/Linear";
import OlaFruits from "./Components/OlaFruits";
import Jobs from "./Components/Jobs";
import IntroAI from "./Components/IntroAI";
import AzureAI from "./Components/AzureAI";
import GenerativeAI from "./Components/GenerativeAI";
import VertexAI from "./Components/VertexAI";
// import ImageClassification from "./Components/ImageClassification";
import Certifications from "./Components/Certifications";
import FaceRecognition from "./Components/FaceRecognition";
import SentimentAnalysis from "./Components/SentimentAnalysis";
// import SemiConductor from "./Components/SemiConductor";
// import SemiConductor from "./Components/SemiConductor";
import Afro_AI from "./Components/Afro_AI";
import Semi from "./Components/Semi";  
import ArduinoP from "./Components/ArduinoP";
import SnakeGame from "./Components/SnakeGame";
import Mic2Ex from "./Components/Mic2Ex";
import Back from "./Components/Back";
import QuantumComp from "./Components/QuantumComp";
import D3Chart from './Components/D3Chart';
import BubbleChart from "./Components/BubbleChart";
import BarChart from "./Components/BarChart";
import EnergyConsum from "./Components/EnergyConsum";
import Quick from "./Components/Quick";
import Merge from "./Components/Merge";
import Bubble from "./Components/Bubble";
import Insertion from "./Components/Insertion";
import Selection from "./Components/Selection";
import Array from "./Components/Array";
import Link from "./Components/Link";
import Strings from "./Components/Strings";
import Tress from "./Components/Tress";
import Amazon from "./Components/Amazon";
import DeepRacer from './Components/DeepRacer';
import Tokenization from "./Components/Tokenization";
import BellCurve from "./Components/BellCurve";
import ShoppingCart from "./Components/ShoppingCart";
import Project from './Components/Project'
function App() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return (
        <div className="App">
          {/* <AuthContextProvider> */}
        <Navbar />
    
        <Router>
            <Routes>
            
                <Route path="/Projects" element={<Projects />} />
                <Route path="/" element={<Home />} />
                <Route path="/Blog" element={<Blog />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Skills" element={<Skills />} />
                <Route path="/Resume" element={<Resume />} />
                <Route path="/FutureAI" element={<FutureAI />} />
                <Route path="/Tutorials" element={<Tutorials />} />
                <Route path="/Quantum" element={<Quantum />} />
                <Route path="/HTCSS" element={<HTCSS />} />
                <Route path="/React_Tutorial" element={<React_Tutorial />} />
                <Route path="/AI_dev" element={<AI_dev />} />
                <Route path="/Transformer" element={<Transformer />} />
                <Route path="/AI_Programmer" element={<AI_Programmer />} />
                <Route path="/Command_line" element={<Command_line />} />
                <Route path="/Learn" element={<Learn />} />
                <Route path="/Soccer" element={<Soccer />} />
                <Route path="/Tensor" element={<Tensor />} />
                <Route path="/Podcast" element={<Podcast />} />
                <Route path="/Register" element={<Register />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/Signin" element={<Signin />} />
                <Route path="*" element={<FourOFour />} />
                <Route path="/Courses" element={<Courses />} />
                <Route path="/Linear" element={<Linear />} />
                <Route path="/Developer" element={<Developer />} />
                <Route path="/OlaFruits" element={<OlaFruits />} />
                <Route path="/Jobs" element={<Jobs />} />
                <Route path="/Certifications" elements={<Certifications />} />
                <Route path="/FaceRecognition" element={<FaceRecognition />} />
                <Route path="/SentimentAnalysis" element={<SentimentAnalysis />} />
                <Route path="/IntroAI" element={<IntroAI />} />
                <Route path="/AzureAI" element={<AzureAI />} />
                <Route path="/GenerativeAI" element={<GenerativeAI />} />
                <Route path="/VertexAI" element={<VertexAI />} />
                <Route path="/Afro_AI" element={<Afro_AI />} />
               <Route path="/Semi" element={<Semi />} />
               <Route path="/ArduinoP" element={<ArduinoP />} />
               <Route path="/SnakeGame" element={<SnakeGame />} />
               <Route path="/Mic2Ex" element={<Mic2Ex />} />
               <Route path="/Back" element={<Back />} />
               <Route path="/QuantumComp" element={<QuantumComp />} />
               <Route path="/D3Chart" element={<D3Chart />} />
               <Route path="/BarChart" element={<BarChart />} />
               <Route path="/BubbleChart" element={<BubbleChart />} />
               <Route path="/EnergyConsum" element={<EnergyConsum />} />
               <Route path="/Quick" element={<Quick />} />
               <Route path="/Merge" element={<Merge />} />
               <Route path="/Insertion" element={<Insertion />} />
               <Route path="/Bubble" element={<Bubble />} />
               <Route path="/Strings" element={<Strings />} />
               <Route path="/Array" element={<Array />} />
               <Route path="/Link" element={<Link />} />
               <Route path="/Tress" element={<Tress />} />
               <Route path="/Amazon" element={<Amazon />} />
               <Route path="/Selection" element={<Selection />} />
               <Route path="/DeepRacer" element={<DeepRacer />} />
               <Route path="/Tokenization" element={<Tokenization />} />
               <Route path="/BellCurve" element={<BellCurve />} />
               <Route path="/ShoppingCart" element={<ShoppingCart /> } />
               <Route path="/Project" element={<Project /> } />
            </Routes>
        </Router>
            
            {init && <Particles options={particlesOptions}/>}
            <header className="App-header">
            <br />
            </header>
            <Back />
<Footer />
        </div>
    );
}

export default App;
